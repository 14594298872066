// **********************************************************************
// DATABASE tz034402db
// **********************************************************************
global.testing = process.env.REACT_APP_POLPLAST_TEST == 0 ? false : true;

// **********************************************************************

global.debugging = global.testing;
global.version = '1.0';
global.items_max = 12; // pagination - max.items na stránke
global.tabsize = 30;

// **********************************************************************
// WINDOW
// **********************************************************************
global.elevation = 1;
global.dialog_bar_height = 42;
global.goto_start_delay = 300;

// **********************************************************************
// DATABAZA - LINK
// **********************************************************************
global.web = global.testing == true ? 'https://polplast.datadream-test.sk' : 'https://www.polplast.sk';
global.web_product = 'https://www.polplast.sk/product/';
global.www = 'www.polplast.sk';
global.db_url = global.web + '/app_web/';
global.images = global.web + '/public/images/';
global.downloads = global.web + '/public/downloads/';
global.product_image = global.web + '/public/images/eshop/';
global.blog_image = global.web + '/public/images/blog/';
global.blog_type_image = global.web + '/public/images/blog_types/';
global.pictograms = global.web + '/public/images/pictograms/';
global.creator = 'Datadream s.r.o';
global.reklamacie_video = "https://www.polplast.sk/public/uploads/video/reklamacie.mp4";

// **********************************************************************
// COLORS
// **********************************************************************
global.theme_back = '#f9f9f9';
global.theme_white = '#ffffff';
global.theme_black = '#000000';
global.theme_black_opacity = '#000000bb';
global.theme_light = '#838D9E';
global.theme_medium = '#495363';
global.theme_light_blue = '#509DF4';
global.theme_lighteen_blue = '#E2ECFF';
global.theme_lighter_blue = '#ADCCEF';
global.theme_light_red = '#FFDDDD';
global.theme_light_green = '#C0E0B8';
global.theme_lighter = '#C3CDDA';
global.theme_dark = '#252F3E';
global.theme_darker = '#1B2330';
global.theme_dark_blue = '#16406F';
global.theme_dark_red = '#F44336';
global.theme_darker_red = '#C3170B';

global.theme_darker_green = '#357004';
global.theme_dark_green = '#69A73C' //'#4D7B4D';
global.theme_blue = '#0078FF';
global.theme_red = '#F77B72';
global.theme_lighteen_red = '#FFE4E2';
global.theme_green = 'green';
global.theme_badge = '#A12E28';
global.theme_placeholder = '#cccccc';
global.theme_light_violet = '#FFAAFF';
global.theme_none = '#00000000';

global.theme_gray = '#eeeeee';
global.theme_xxgray = '#aaaaaa';
global.theme_dark_gray = '#666666';
global.theme_medium_gray = '#888888';
global.theme_light_gray = '#dedede';
//global.theme_menu = '#004527';

global.theme_menu_categories = '#EFC895' // '#F44336';
global.theme_menu = '#69A73C' // '#005E36';
global.theme_menu_top = '#E3F1D9' // '#005E36';
global.theme_menu_selected = '#aa0000';
global.theme_menu_background_selected = '#ffeeee';
global.theme_submenu = '#aaaaaa';
global.theme_categorymenu = '#ffffff';

global.theme_selected = '#4D617B';
global.theme_orange = 'orange';
global.theme_violet = 'violet';
global.theme_yellow = '#FFFF77';
global.theme_brown = 'brown';

global.theme_button = '#36666E';
global.theme_button_buy = '#F44336';
global.theme_button_link = '#209FB6';
global.theme_box_label = '#222222';
global.theme_box_label_over = '#AA3333';

global.theme_new_products = '#f0f0f0';

global.theme_footer_text = '#AAAAAA';
global.theme_footer_text_high = '#CCCCCC';
global.theme_footer = '#222222';

global.theme_product_new = '#4D7B4D';
global.theme_favorite = '#F44336';
global.theme_error = '#CA0000';

global.window_bar_color = 'linear-gradient(to right,#E3F1D9,#A3D1A3)';

// **********************************************************************
// DISPLAY SIZE
// **********************************************************************
global.device_small = 600;
global.device_medium = 992;
global.screen_max = 950;

// **********************************************************************
// FONT SIZE
// **********************************************************************
global.font_xxxtiny = 10;
global.font_xxtiny = 12;
global.font_tiny = 14;
global.font_small = 16;
global.font_main_menu = 15;
global.font_normal = 18;
global.font_large = 20;
global.font_xlarge = 24;
global.font_xxlarge = 28;
global.font_label = 34;
global.font_dialog_label = 24; // dialogove boxy - label
global.font_menu = 18; // menu - lava strana
global.font_textlabel = 13; // label pre TextField <p></p>
global.font_categorymenu = 14;
global.font_products_grid = 16;
global.font_banner_label = 36;
global.font_banner_text = 18;
global.font_banner_label_small = 18;
global.font_banner_text_small = 14;
global.font_prices = 22;
global.font_blog = 20;

// **********************************************************************
// RADIUS
// **********************************************************************
global.radius = 5;

// **********************************************************************
// pocet produktov na stranu
// **********************************************************************
global.show_1 = 12;
global.show_2 = 24;
global.show_3 = 36;

// **********************************************************************
// MENU INDEX
// **********************************************************************
global.menu_padding = 40;
global.menu_button_width = 130;

global.menu_main = 0;

global.menu_action = 1004;
global.menu_subaction = 9377;

// *********************************************************************************************************
// FLAGS - nalepky na produktoch
// *********************************************************************************************************
global.flags = [
    'Bez značky',
    'Novinka jar',
    'Novinka Veľká noc',
    'Novinka Dušičky',
    'Novinka Vianoce',
]

global.flags_color = [
    global.theme_white,
    '#00AA00',
    '#FFFF00',
    global.theme_orange,
    '#AA0000',
]

global.flags_text_color = [
    global.theme_black,
    global.theme_white,
    global.theme_black,
    global.theme_white,
    global.theme_white,
]

// **********************************************************************
// CAS NAVSTEVY
// **********************************************************************
global.time = [
    { id: 0, time: '9:00' },
    { id: 1, time: '9:30' },
    { id: 2, time: '10:00' },
    { id: 3, time: '10:30' },
    { id: 4, time: '11:00' },
    { id: 5, time: '13:00' },
    { id: 6, time: '13:30' },
    { id: 7, time: '14:00' },
    { id: 8, time: '14:30' },
    { id: 9, time: '15:00' },
];

// **********************************************************************
// VIDEO
// **********************************************************************
global.presentation_intro = [
    { id: 1, video: 'intro.mp4', name: 'Novinky v spoločnosti' },
    { id: 2, video: 'intro_2.mp4', name: 'Nové produkty' },
    { id: 3, video: 'intro_3.mp4', name: 'Najnovšia produktová rada' },
    { id: 4, video: 'intro_4.mp4', name: 'Najnovšie trendy' },
];

global.presentation_ending = [
    { id: 1, video: 'ending.mp4', name: 'Email a kontakt' },
    { id: 2, video: 'ending_2.mp4', name: 'Novinky na eshope' },
];


// **********************************************************************
// SK - LANGUAGE
// **********************************************************************
export const sk = {
    dph: 23,
    dph_coeficient: 1.3,
    title: 'Polplast',
    description: 'Profesional vo svete kvetináčov',
    home: 'Domov',

    status: ['Čaká na prijatie', 'V spracovaní', 'Expedované', 'Pripravená na výdaj', 'Ukončené', 'Zrušené'],

    login: 'Prihlásenie',
    loginname: 'Prihlasovacie meno (e-mail)',
    login_data: 'Prihlasovacie údaje',
    username: 'Prihlasovacie meno',
    password: 'Heslo',
    password_: 'Heslo (minimálna dĺžka 8 znakov)',
    password_change: 'Zmena hesla',
    password_new: 'Zadajte nové heslo (minimálne 8 znakov)',
    password_new_again: 'Zopakujte nové heslo',
    passwords_not_equal: 'Zadané heslá sa nezhodujú',
    password_ok: 'Heslo bolo úspešne zmenené',
    password_error: 'Pri zmene hesla nastala chyba. Skúste požiadavku opakovať.',
    password_min_len: 'Minimálna dĺžka hesla je 8 znakov',
    password_change: 'Zmeniť heslo',
    login_button: 'Prihlásiť sa',
    lost_password_button: 'Zabudli ste heslo?',
    login_error: 'Nesprávne prihlasovacie údaje',
    lost_password: 'Zabudnuté heslo',

    register: 'Nová registrácia',
    register_button: 'Registrovať sa',
    register_btn: 'Registrovať',
    register_login: 'Prihlasovacie údaje do systému',
    register_ok: 'Registrácia je úspešne dokončená.',
    register_error: 'Pri registrácii nastala chyba. Skúste požiadavku opakovať.',
    forgot_password_email1: 'Zadejte Vašu e-mailovu adresu.',
    forgot_password_email2: 'My vám zašleme odkaz pro změnu hesla',
    forgot_email_error: 'Zadaná e-mailová adresa už je registrovaná v našom systéme',
    forgot_email_no_exists: 'Zadaná e-mailová adresa nie je registrovaná v našom systéme',
    required_data: 'Údaje označené * sú povinné',

    verify: 'Overiť',
    verification_code: 'Overovací kód',
    verification_code_enter: 'Overovací kód sme odoslali na Vašu e-mailovú adresu. Opíšte overovací, ktorý nájdete vo Vašom maile',
    verification_code_error: 'Nesprávny kód',
    verification_code_resend: 'Nedostali ste overovací kód? Odoslať kód.',

    months: ['Január', 'Február', 'Marec', 'Apríl', 'Máj', 'Jún', 'Júl', 'August', 'September', 'Október', 'November', 'December'],
    months_short: ['Jan.', 'Feb.', 'Mar.', 'Apr.', 'Máj.', 'Jún.', 'Júl.', 'Aug.', 'Sep.', 'Okt.', 'Nov.', 'Dec.'],
    days: ['Nedeľa', 'Pondelok', 'Utorok', 'Streda', 'Štvrtok', 'Piatok', 'Sobota'],
    days_short: ['Ned.', 'Pon.', 'Uto.', 'Str.', 'Štv.', 'Pia.', 'Sob.'],
    today: 'Dnes',

    company: 'Firma / Spoločnosť',
    name: 'Meno',
    surname: 'Priezvisko',
    name_surname: 'Meno a priezvisko',
    street: 'Ulica',
    street_number: 'číslo',
    psc: 'PSČ',
    town: 'Mesto',
    email: 'E-mail',
    email_error: 'Nesprávny formát e-mailovej adresy',
    mobil: 'Mobil',
    phone: 'Tel.číslo',
    state: 'Štát',
    logo: 'Logo',
    ico: 'IČO',
    dic: 'DIČ',
    ic_dph: 'IČ-DPH',
    DPH: 'DPH',

    continue: 'Pokračovať',
    back: 'Späť',
    close: 'Zavrieť',

    required: 'Povinný údaj',
    username_min_len: 'Minimálna dĺžka prihlasovacieho mena musí byť 8 znakov',
    password_min_len: 'Minimálna dĺžka hesla musí byť 8 znakov',
    email_checking: 'Prebieha kontrola e-mailovej adresy',
    username_checking: 'Prebieha kontrola prihlasovacieho mena',
    password_exists: 'Zadaná e-mailová adresa už je zaregistrovaná. Použite inú adresu.',
    username_exists: 'Zadané prihlasovacie meno už používa iný účet. Použité iné meno',
    logout: 'Odhlásiť sa',
    logout_question: 'Chcete sa odhlásiť',
    loged_user: 'Prihlásený užívateľ',

    active: 'Aktívny',
    number: 'Číslo',
    pin: 'Bezpečnostný pin (6 miestny)',
    user_data: 'Základné údaje',
    active: 'Aktívny',
    label: 'Názov',
    db_error: 'Pri zápise nastala chyba. Skúste požiadavku opakovať',

    new: 'Nový',
    add: 'Pridať',
    ok: 'OK',
    cancel: 'Zrušiť',
    save: 'Uložiť',
    save_changes: 'Uložiť zmeny',
    edit: 'Upraviť',
    search: 'Hľadať',
    send: 'Odoslať',
    send_question: 'Odoslať otázku',
    saving: '...',
    subscribe: 'Odoberať',
    question: 'Otázka',

    call_us: 'Volajte',

    shopping_card: 'Nákupný košík',
    card_data: 'Obsah košíka',

    categories: 'Kategórie',
    show_categories: 'Kategórie',
    hide_categories: 'Skryť',
    show_more: 'Zobraziť ďalšie',
    go_upper: 'Hore',
    show: 'Zobraziť',
    display_grid: 'Dlaždicový',
    display_line: 'Riadkový',
    variant: 'Varianta',
    choose_variant: 'Vyberte variantu',
    choose: 'Výber',
    code: 'Obj.kód',
    stock: 'Skladom',
    no_stock: 'Nie je skladom',
    pieces: 'ks',
    buy: 'Do košíka',
    price: 'Cena',
    money: '€',
    category: 'Kategória',
    product_buy: 'Produkt',
    gdpr: 'Ochrana osobných údajov',
    cookies_rules: 'Zásady používania súborov cookies',
    sell_docs: 'Všeobecné obchodné podmienky',
    reklamacny_poriadok: 'Reklamačný poriadok',
    reklamacny_poriadok_video: 'Ako vytvoriť reklamáciu',
    delivery_payment: 'Doručenie a platobné podmienky',
    video_tutorial: 'Video návod',

    my_account: 'Môj účet',
    account_settings: 'Nastavenia účtu',
    account_history: 'História nákupov',
    account_products: 'Zakúpené produkty',
    basic_data: 'Základné údaje',
    facture_data: 'Fakturačné údaje',
    delivery_address: 'Dodacia adresa',
    delivery_address_same: 'Dodacia adresa je rovnaká ako fakturačná',
    b2b: 'Veľkoobchod',
    b2b_prices: 'B2B zákazník',
    b2b_user: 'Som podnikateľ / firma',
    b2b_user_text: 'Registrácia slúži výhradne pre živnostníkov a spoločnosti, ktoré sú držiteľom živnostenského oprávnenia za účelom predaja kvetín, bytových a záhradných doplnkov a realizácií interiérov a záhrad, t.j. pre tých, ktorí s našim sortimentom ďalej obchodujú. Pre splnenie podmienok registrácie vyplňte, prosím, všetky nasledujúce údaje. Informáciu o korektnom zaregistrovaní obdržíte na Vami uvedenú e-mailovú adresu.',
    action: 'Akcia',
    start_eshop: 'Začať nakupovať',
    new_items: 'Nové produkty v e-shope',
    price_starts: 'Cena od',
    buy_button: 'Kúpiť',
    hours: 'Otváracie hodiny',
    info: 'Informácie',
    newsletter: 'Newsletter',
    newsletter_receive_ok: 'Odber noviniek bol úspešne aktivovaný',
    newsletter_receive_err: 'Neplatný link. Zaregistrujte platný e-mail.',
    newsletter_receive: 'Newsletter - prihlásiť sa na odber',
    newsletter_email_error: 'E-mailová adresa už je registrovaná',
    contact_us: 'Kontaktujte nás',
    email_yours: 'Váš e-mail ...',
    trades: 'Harmonogram rozvozu',
    main_page: 'Prejsť na stránku',
    sum_with_dph: 'Suma vrátane DPH',
    sum_without_dph: 'Suma bez DPH',

    with_dph: 'Cena vrátane DPH',
    with_dph_: 'Cena vrátane zľavenej DPH',
    price_with: 'Cena vrátane',

    sum_dph: 'Suma vrátane DPH',
    sum_dph_: 'Suma vrátane zľavenej DPH',
    sum_with: 'Suma vrátane',

    without_dph: 'Cena bez DPH',
    dph_true: 's DPH',
    dph_false: 'bez DPH',
    to_checkout: 'K pokladni',
    checkout_step_1: 'Doprava a platba',
    checkout_step_2: 'Adresa dodania',
    checkout_step_3: 'Zhrnutie objednávky',
    checkout_step_4: 'Ďakujeme za objednávku.',
    checkout_error: 'Nastala chyba',
    checkout_error_text: 'Pri odosielaní objednávky nastala chyba, skúste požiadavku opakovať.',
    back_to_shop: 'Späť do obchodu',

    added_to_card: 'Pridané do košíka',
    continue_to_shop: 'Pokračovať v nákupe',
    continue_to_card: 'Pokračovať do košíka',

    card_empty: 'V košíku sa nenachádza žiaden produkt',
    trade: 'Spôsob dopravy',
    payment: 'Spôsob platby',
    free: 'zadarmo',
    price_item: 'Jedn.cena',
    quantity: 'Množstvo',
    send_order: 'Odoslať objednávku',
    gdpr_agree: 'Súhlasím so všeobecnými obchodnými podmienkami a spracovaním osobných údajov.',
    gdpr_agree_: 'Odoslaním objednávky vyjadrujem súhlas so všeobecnými obchodnými podmienkami a spracovaním osobných údajov.',
    adress_delivery: 'Adresa doručenia',
    contact_data: 'Kontaktné údaje',
    state_sk: 'Slovensko',
    gdpr_error: 'Chýba súhlas so všeobecnými obchodnými podmienkami a spracovaním osobných údajov.',
    newsletter_register: 'Prihlásiť sa na odber noviniek a akcii prostredníctvom e-mailu',
    order_note: 'Poznámka k objednávke',
    main_page: 'Späť na hlavnú stránku',
    order_finish_text: 'Ďakujeme za objednávku.',
    order_number: 'Objednávka bola úspešne dokončena. Číslo objednávky',
    order_finish_email: 'Potvrdenie sme Vám zaslali na e-mail',
    order_finish_text: 'Informácie o stave objednávky Vám budeme priebežne posielať na Váš e-mail.',
    recomended_items: 'Odporúčame dokúpiť',
    search_error: 'Hľadaný výraz musí mať aspoň 3 znaky',
    search_text: 'Hľadaný výraz',
    search_count: 'Počet nájdených produktov',
    search_none: 'Hľadaný výraz nemôžeme v našom katalógu nájsť. Skúste, prosím, použiť iný výraz.',
    manufacture: 'Výrobca',
    product_new: 'Novinka',
    product_sale: 'Výpredaj',
    add_to_card: 'Vložiť do košíka',
    product_details: 'Zobraziť produkt',
    service1: 'Neváhajte nás navštíviť',
    service1_text: 'v kamennej predajni',
    service2: 'Doručenie tovaru zdarma',
    service2_text: 'dovozom Polplast SK',
    service3: 'Dovoz kuriérom zdarma',
    service3_text: 'nad 100,00 € bez DPH do 48 hod.',
    service4: 'Sme tu už 29 rokov',
    service4_text: 'a stále rastieme',
    reserve_termin: 'Objednať sa',
    reserve_label: 'Objednajte sa k nám',
    reserve_text: 'Rezervačný formulár',
    reserve_date: 'Vyberte si deň a čas návštevy',
    reserve_date_error: 'Zvoľte dátum návštevy',
    reserve_date_text1: 'Objednať sa môžete cez pracovné dni od pondelka do štvrtka',
    reserve_date_text2: 'v čase 9:00-11:00 a 13:00-15:00',
    reserve_send: 'Objednať sa',
    reserve_note: 'Poznámka k objednávke',
    reserve_thank: 'Ďakujeme za prejavený záujem o rezerváciu',
    reserve_sended: 'Žiadosť bola úspešne odoslaná.',
    reserve_contact: 'Termín návštevy Vám potvrdíme e-mailom, alebo telefonicky.',
    product_favorite: 'Do obľúbených',
    product_ask: 'Opýtať sa predávajúceho',
    product_watch: 'Strážit dostupnosť',
    product_ask_text: 'na daný produkt',
    product_ask_ok1: 'Vaša otázka bola odoslaná',
    product_ask_ok2: 'Odpovieme Vám na zadaný e-mail, alebo telefonicky',
    favorites_add: 'Pridať do obľúbených',
    favorites_added: 'Produkt bol pridaný do obľúbených',
    favorite: 'Obľúbené',
    favorite_empty: 'Zoznam je prázdny',
    favorite_items: 'Obľúbené položky',
    favorite_login: 'Pre používanie funkcie obľúbených produktov sa musíte prihlásiť, alebo registrovať!',
    product_name: ' Názov produktu',
    contact: 'Kontakt',
    contact_data: 'Kontaktné údaje',
    language: 'Jazyk',
    message: 'Správa',
    message_text_error: 'Chýba text správy',
    message_text: 'Text správy',
    message_send_od: 'Správa bola úspešne odoslaná',
    message_question: 'Chcete sa na niečo opýtať? Napíšte nám.',
    watchdog_button: 'Nastaviť stráženie',
    watchdog_text: 'Keď bude produkt dostupný, pošleme Vám e-mail',
    watchdog_set: 'Stráženie nastavené',
    your_email: 'Vaša e-mailová adresa',
    blog_title: 'Vitajte a nechajte sa inšpirovať!',
    asking: 'Pýtate sa',
    asking_text: 'Vaše najčastejšie otázky',
    blog: 'Blog',
    cookies: 'Na poskytovanie služieb využívame len nevyhnutné cookies. Prechádzaním webu vyjadrujete súhlas s ich používaním.',
    cookies_info: 'Viac informácii',
    agree: 'Súhlasím',
    contact_central: 'Centrála a maloobchod',
    contact_stock: 'Sklad',
    orders_list: 'Zoznam objednávok',
    order_detail: 'Detail objednávky',
    note: 'Poznámka',
    order_products: 'Zoznam zakúpených produktov',
    order_status: 'Stav objednávky',
    cost_with_dph: 'Suma s DPH',
    cost_without_dph: 'Suma bez DPH',
    user_discount: 'Poskytnutá zľava na tovar',
    discount_text: 'Zľava 2 % za každý online nákup nad 50.00 bez DPH',
    show_on_stack: 'len skladom',
    catalogue: 'Katalóg',

    register_type: 'Vyberte si typ registrácie',
    register_b2c: 'Koncový zákazník',
    register_b2b: 'Mám IČO, som obchodník',

    voucher: 'Zľavový kupón',
    voucher_use: 'Uplatniť',
    voucher_error: 'Neplatný zľavový kupón',
    voucher_error_sum: 'Minimálna výška objednávky produktov musi byť',

    products_sum: 'Suma za produkty',

    product_details: 'Technické parametre',

    reklamacia: 'Reklamácia',
    reklamacie: 'Reklamácie',
    reklamacie_list: 'Zoznam reklamácií',
    reklamacia_create: 'Reklamovať',
    reklamacia_title: 'Reklamácia objednávky',
    reklamacia_create_text: 'Vytvorenie reklamácie k objednávke číslo',
    reklamacia_select: 'Označte produkty, ktoré reklamujete a zadajte počet reklamovaných kusov',
    reklamacia_products: 'Zoznam reklamovaných produktov a počet reklamovaných kusov',
    reklamacia_status: 'Stav reklamácie',
    reklamacia_statuses: ['Vytvorená', 'V posudzovaní', 'Schválená', 'Zamietnutá'],
    reklamacia_reason: 'Dôvod reklamácie',
    reklamacia_reasons: [
        { id: 1, name: 'Poškodený tovar' },
        { id: 2, name: 'Nesprávne dodaný tovar' },
        { id: 3, name: 'Nesprávne množstvo dodaného tovaru' },
        { id: 4, name: 'Nesprávna cena' },
        { id: 99, name: 'Iný dôvod' },
    ],
    reklamacia_text: 'Text reklamácie',
    reklamacia_text_: 'Sprievodný text k reklamácii ...',
    reklamacia_faktúra: 'Číslo faktúry, daňového dokladu',
    reklamacia_deny_reason: 'Dôvod zamietnutia reklamácie',
    reklamacia_send: 'Odoslať',
    reklamacia_send_ask: 'Odoslať reklamáciu',
    reklamacia_quantity: 'Počet reklamovaných kusov',
    reklamacia_quantity_: 'Pôvodne objednaný počet',
    reklamacia_send_ask_text: 'Chcete odoslať reklamáciu na reklamačné oddelenie Polplast SK?',
    reklamacia_photos: 'Fotografie',
    reklamacia_photos_: 'Príloha reklamácie - fotografie (maximálne 5 kusov)',
    reklamacia_page_2: 'Doplňte dôvod reklamácie a fotografie',
    reklamacia_sended: 'Reklamácia bola úspešne odoslana.',
    reklamacia_sended_text: 'Stav reklamácie môžete sledovať v zozname reklamácii',
    reklamacia_request: 'Spôsob, akým požadujem vybaviť reklamáciu',
    reklamacia_reason_missing: 'Vyberte dôvod reklamácie',
    reklamacia_requests: [
        { id: 1, name: 'Výmena tovaru' },
        { id: 2, name: 'Dobropis' },
        { id: 3, name: 'Výber iného tovaru' },
        { id: 4, name: 'Dodanie chýbajúceho tovaru' },
    ],

    yes: 'Áno',
    no: 'Nie',
    delete: 'Vymazať',

    photos: 'Fotografie',
    photo: 'Fotografia',
    photo_select_title: 'Výber fotografie',
    photo_select: 'Vybrať fotografiu',
    photo_change: 'Zmeniť fotografiu',
    photo_delete: 'Vymazať',
    photo_delete_text: 'Chcete vymazať fotografiu?',
    photo_choose: 'Výber fotografie',
    photo_save: 'Vložiť',
    photo_min: 'Minimálna veľkosť',
    photo_add: 'Pridať fotografiu',

    items_none: 'Žiadne položky',
    none_entered: 'Nezadané',

    presentation_disabled: 'Prezentácia nie je k dispozícii',
}


global.reklamacia_colors = ['#D0D0D0', '#0044FF', '#00D000', '#D00000'];
global.reklamacia_font_colors = ['#000000', '#FFFFFF', '#FFFFFF', '#FFFFFF'];